<template>
    <comp-form title="分类" ref="comp_form" :forms="forms" submit-api="/gateway/api/synursing/pc/cat/addCat" edit-api="/gateway/api/synursing/pc/cat/updateCat" id-key="catId" :submit-before="onSubmitBefore" @on-submit="onReset" height="auto"></comp-form>
</template>

<script>
import CompForm from "../jointly/components/CompForm"
import ElemFormItem from "../jointly/components/elem-form-item.vue"

export default {
    components: { CompForm, ElemFormItem },

    data() {
        return {
            forms: [],
        }
    },

    methods: {
        /**
         * 打开表单窗口
         */
        async open(id, data) {
            await this.$get("/gateway/api/synursing/app/cat/selectByOrgCode?orgCode=" + parent.vue.loginInfo.userinfo.orgCode).then(res => {
                if (res.code != 200) {
                    return Taro.showModal({
                        title: "提示",
                        content: res.data?.desc?.slice(0, 50) || "请求失败，请稍后再试！",
                    })
                }

                const list = res.dataList || []
                // 保存分类数据
                this.catList = list
                // 定义表单结构
                this.forms = [
                    {
                        type: "input",
                        title: "姓名",
                        name: "userName",
                        required: true,
                    },
                    {
                        type: "input",
                        title: "性别",
                        name: "sex",
                        required: true,
                    },
                    {
                        type: "number",
                        title: "年龄",
                        name: "age",
                        required: true,
                    },
                    {
                        type: "input",
                        title: "联系电话",
                        name: "mobile",
                    },
                    {
                        type: "textarea",
                        title: "护理问题",
                        name: "content",
                        required: true,
                    },
                    ...list.map((v, i) => {
                        return {
                            type: "checkbox",
                            title: v.catName,
                            name: "cat-" + v.catId,
                            required: true,
                            options: v.nursingItemVos?.map(n => ({
                                value: n.itemId,
                                name: n.itemName,
                                formdata: {
                                    content: n.itemContent,
                                },
                                form: {
                                    title: "编辑指导内容",
                                    forms: [
                                        {
                                            type: "textarea",
                                            title: "指导内容",
                                            name: "content",
                                        },
                                    ],
                                },
                            })),
                        }
                    }),
                    {
                        type: "upload",
                        title: "服务照片",
                        name: "photo",
                        required: true,
                    },
                    {
                        type: "input",
                        title: "康护师",
                        name: "nursingUser",
                        required: true,
                    },
                    {
                        type: "datePicker",
                        title: "服务时间",
                        name: "operatorTime",
                        required: true,
                    },
                ]

                // 如果存在 ID，获取详情
                if (this.id) {
                    this.getDateil()
                }
            })

            this.$refs.comp_form.open(id, data)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 提交数据前置事件
         */
        onSubmitBefore(value) {
            const items = []

            value = JSON.parse(JSON.stringify(value))

            Object.keys(value)
                .filter(k => /^cat-.*$/.test(k))
                .forEach(k => {
                    let catId = k.replace(/^cat-(.*)$/, "$1")
                    let cat = this.catList.find(v => v.catId === Number(catId))
                    // 循环选项
                    value[k].forEach(v => {
                        let item = cat.nursingItemVos.find(c => c.itemId === v.value)
                        items.push({
                            itemId: item.itemId,
                            catId: item.catId,
                            itemName: item.itemName,
                            itemContent: v.formdata.content,
                            itemSort: item.itemSort,
                        })
                    })
                    delete value[k]
                })

            return {
                ...value,
                creatorId: this.$core.getGlobalId(),
                dataScopeId: this.$core.getOrgCode(),
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                nursingItemVos: items,
            }
        },

        onChangeIcon(e) {
            this.$refs.comp_form.setValue("icon", e.value.url)
        },
    },
}
</script>
